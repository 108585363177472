import {
  Benzinga,
  BusinessTimes,
  CointQuora,
  Feature1,
  Feature2,
  Feature3,
  Feature4,
  Feature5,
  Feature6,
  Investing,
  Marketwatch,
  P1,
  P1Backers,
  P2,
  P2Backers,
  P3,
  P3Backers,
  P4,
  P4Backers,
  P5,
  P5Backers,
  P6,
  P6Backers,
  P7,
  P7Backers,
  P8,
  P8Backers,
  P9,
  P9Backers,
  P10,
  P10Backers,
  P11,
  P11Backers,
  P12,
  P12Backers,
  P13,
  P13Backers,
  P14,
  P14Backers,
  P15,
  P15Backers,
  P16,
  P16Backers,
  P17,
  P17Backers,
  P18,
  P18Backers,
  P19,
  P19Backers,
  P20,
  P20Backers,
  P21,
  P21Backers,
  P22,
  P22Backers,
  P23,
  P24,
  P25,
  P26,
  P27,
  P28,
  P29,
  P30,
  P31,
  P32,
  P33,
  P34,
  P35,
  P36,
  P37,
  P38,
  P39,
  P40,
  P41,
  P42,
  P43,
  Slide1,
  Slide2,
  Slide3,
  P23Backers,
  P24Backers,
  P25Backers,
} from '@/assets/img/sections';

export const slides = [{ img: Slide1 }, { img: Slide2 }, { img: Slide3 }, { img: Slide1 }];

// export const earnItems = [
//   {
//     token0: {
//       symbol: 'BOT',
//       img: Bot,
//     },
//     token1: {
//       symbol: 'BNB',
//       img: Bsc,
//     },
//     apy: '12.15',
//   },
//   {
//     token0: {
//       symbol: 'BOT',
//       img: Bot,
//     },
//     token1: {
//       symbol: 'BNB',
//       img: Bsc,
//     },
//     apy: '15.5',
//   },
//   {
//     token0: {
//       symbol: 'BOT',
//       img: Bot,
//     },
//     token1: {
//       symbol: 'BNB',
//       img: Bsc,
//     },
//     apy: '25.15',
//   },
//   {
//     token0: {
//       symbol: 'BOT',
//       img: Bot,
//     },
//     token1: {
//       symbol: 'BNB',
//       img: Bsc,
//     },
//     apy: '75.15',
//   },
//   {
//     token0: {
//       symbol: 'BOT',
//       img: Bot,
//     },
//     token1: {
//       symbol: 'BNB',
//       img: Bsc,
//     },
//     apy: '75.15',
//   },
// ];

export const stakingItems = [
  {
    content: 'Lock for a 1 month',
    apy: '70',
  },
  {
    content: 'Lock for a 3 month',
    apy: '75',
  },
  {
    content: 'Lock for a 6 month',
    apy: '90',
  },
  {
    content: 'Lock for a year',
    apy: '120',
  },
];

export const featuresItems = [
  {
    title: 'Lowest fee in DeFi',
    text: 'Swap Fast & Easy! Transaction Fee 0,01%',
    img: Feature1,
  },
  {
    title: 'Fixed staking APY',
    text: 'Staking Fixed Interest Staking APY up to 120%',
    img: Feature2,
  },
  {
    title: 'Farming',
    text: 'Farm season with high yield',
    img: Feature3,
  },
  {
    title: 'NFT Staking',
    text: 'Stake your NFT and get a huge gaming experience!',
    img: Feature4,
  },
  {
    title: 'Cross-chain',
    text: 'Cross-chain swaps between any arbitrary assets in one transaction!',
    img: Feature5,
  },
  {
    title: 'Diverse staking rewards',
    text: 'From earning experience in the game to earning tokens',
    img: Feature6,
  },
];

export const partnersCardItems = [
  { img: P1 },
  { img: P2 },
  { img: P3 },
  { img: P4 },
  { img: P5 },
  { img: P6 },
  { img: P7 },
  { img: P8 },
  { img: P9 },
  { img: P10 },
  { img: P11 },
  { img: P12 },
  { img: P13 },
  { img: P14 },
  { img: P15 },
  { img: P16 },
  { img: P17 },
  { img: P18 },
  { img: P19 },
  { img: P20 },
  { img: P21 },
  { img: P22 },
  { img: P23 },
  { img: P24 },
  { img: P25 },
  { img: P26 },
  { img: P27 },
  { img: P28 },
  { img: P29 },
  { img: P30 },
  { img: P31 },
  { img: P32 },
  { img: P33 },
  { img: P34 },
  { img: P35 },
  { img: P36 },
  { img: P37 },
  { img: P38 },
  { img: P39 },
  { img: P40 },
  { img: P41 },
  { img: P42 },
  { img: P43 },
];

export const backersItems = [
  { img: P1Backers },
  { img: P2Backers },
  { img: P3Backers },
  { img: P4Backers },
  { img: P5Backers },
  { img: P6Backers },
  { img: P7Backers },
  { img: P8Backers },
  { img: P9Backers },
  { img: P10Backers },
  { img: P11Backers },
  { img: P12Backers },
  { img: P13Backers },
  { img: P14Backers },
  { img: P15Backers },
  { img: P16Backers },
  { img: P17Backers },
  { img: P18Backers },
  { img: P19Backers },
  { img: P20Backers },
  { img: P21Backers },
  { img: P22Backers },
  { img: P23Backers },
  { img: P24Backers },
  { img: P25Backers },
];

export const partnersSwiperItems = [
  {
    id: 1,
    img: BusinessTimes,
  },
  {
    id: 2,
    img: Investing,
  },
  {
    id: 3,
    img: CointQuora,
  },
  {
    id: 4,
    img: Marketwatch,
  },
  {
    id: 5,
    img: Benzinga,
  },
];
